export const HcButton = () => import('../../components/Atoms/Button/Button.vue' /* webpackChunkName: "components/hc-button" */).then(c => wrapFunctional(c.default || c))
export const HcBreadcrumb = () => import('../../components/Atoms/Breadcrumb/Breadcrumb.vue' /* webpackChunkName: "components/hc-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const HcCarouselCards = () => import('../../components/Atoms/CarouselCards/CarouselCards.vue' /* webpackChunkName: "components/hc-carousel-cards" */).then(c => wrapFunctional(c.default || c))
export const HcCheckbox = () => import('../../components/Atoms/Checkbox/Checkbox.vue' /* webpackChunkName: "components/hc-checkbox" */).then(c => wrapFunctional(c.default || c))
export const HcDivider = () => import('../../components/Atoms/Divider/Divider.vue' /* webpackChunkName: "components/hc-divider" */).then(c => wrapFunctional(c.default || c))
export const HcFieldFeedback = () => import('../../components/Atoms/FieldFeedback/FieldFeedback.vue' /* webpackChunkName: "components/hc-field-feedback" */).then(c => wrapFunctional(c.default || c))
export const HcForm = () => import('../../components/Atoms/Form/Form.vue' /* webpackChunkName: "components/hc-form" */).then(c => wrapFunctional(c.default || c))
export const HcIcon = () => import('../../components/Atoms/Icon/Icon.vue' /* webpackChunkName: "components/hc-icon" */).then(c => wrapFunctional(c.default || c))
export const HcInfoBox = () => import('../../components/Atoms/InfoBox/InfoBox.vue' /* webpackChunkName: "components/hc-info-box" */).then(c => wrapFunctional(c.default || c))
export const HcInput = () => import('../../components/Atoms/Input/Input.vue' /* webpackChunkName: "components/hc-input" */).then(c => wrapFunctional(c.default || c))
export const HcInputCustom = () => import('../../components/Atoms/InputCustom/InputCustom.vue' /* webpackChunkName: "components/hc-input-custom" */).then(c => wrapFunctional(c.default || c))
export const HcInputFile = () => import('../../components/Atoms/InputFile/InputFile.vue' /* webpackChunkName: "components/hc-input-file" */).then(c => wrapFunctional(c.default || c))
export const HcInputFileV2 = () => import('../../components/Atoms/InputFileV2/InputFileV2.vue' /* webpackChunkName: "components/hc-input-file-v2" */).then(c => wrapFunctional(c.default || c))
export const HcInputMultiple = () => import('../../components/Atoms/InputMultiple/InputMultiple.vue' /* webpackChunkName: "components/hc-input-multiple" */).then(c => wrapFunctional(c.default || c))
export const HcLabel = () => import('../../components/Atoms/Label/Label.vue' /* webpackChunkName: "components/hc-label" */).then(c => wrapFunctional(c.default || c))
export const HcLinksList = () => import('../../components/Atoms/LinksList/LinksList.vue' /* webpackChunkName: "components/hc-links-list" */).then(c => wrapFunctional(c.default || c))
export const HcModal = () => import('../../components/Atoms/Modal/Modal.vue' /* webpackChunkName: "components/hc-modal" */).then(c => wrapFunctional(c.default || c))
export const HcNav = () => import('../../components/Atoms/Nav/Nav.vue' /* webpackChunkName: "components/hc-nav" */).then(c => wrapFunctional(c.default || c))
export const HcNavItem = () => import('../../components/Atoms/NavItem/NavItem.vue' /* webpackChunkName: "components/hc-nav-item" */).then(c => wrapFunctional(c.default || c))
export const HcPagination = () => import('../../components/Atoms/Pagination/Pagination.vue' /* webpackChunkName: "components/hc-pagination" */).then(c => wrapFunctional(c.default || c))
export const HcSelect = () => import('../../components/Atoms/Select/Select.vue' /* webpackChunkName: "components/hc-select" */).then(c => wrapFunctional(c.default || c))
export const HcSpeech = () => import('../../components/Atoms/Speech/Speech.vue' /* webpackChunkName: "components/hc-speech" */).then(c => wrapFunctional(c.default || c))
export const HcTagGroup = () => import('../../components/Atoms/TagGroup/TagGroup.vue' /* webpackChunkName: "components/hc-tag-group" */).then(c => wrapFunctional(c.default || c))
export const HcText = () => import('../../components/Atoms/Text/Text.vue' /* webpackChunkName: "components/hc-text" */).then(c => wrapFunctional(c.default || c))
export const HcTextArea = () => import('../../components/Atoms/TextArea/TextArea.vue' /* webpackChunkName: "components/hc-text-area" */).then(c => wrapFunctional(c.default || c))
export const HcArticlesBanner = () => import('../../components/Organisms/ArticlesBanner/ArticlesBanner.vue' /* webpackChunkName: "components/hc-articles-banner" */).then(c => wrapFunctional(c.default || c))
export const HcBannerCardIllustrations = () => import('../../components/Organisms/BannerCardIllustrations/BannerCardIllustrations.vue' /* webpackChunkName: "components/hc-banner-card-illustrations" */).then(c => wrapFunctional(c.default || c))
export const HcCardIllustrations = () => import('../../components/Organisms/CardIllustrations/CardIllustrations.vue' /* webpackChunkName: "components/hc-card-illustrations" */).then(c => wrapFunctional(c.default || c))
export const HcFooter = () => import('../../components/Organisms/Footer/Footer.vue' /* webpackChunkName: "components/hc-footer" */).then(c => wrapFunctional(c.default || c))
export const HcGptSummaryLegacy = () => import('../../components/Organisms/GptSummaryLegacy/GptSummaryLegacy.vue' /* webpackChunkName: "components/hc-gpt-summary-legacy" */).then(c => wrapFunctional(c.default || c))
export const HcGptSummaryStream = () => import('../../components/Organisms/GptSummaryStream/GptSummaryStream.vue' /* webpackChunkName: "components/hc-gpt-summary-stream" */).then(c => wrapFunctional(c.default || c))
export const HcHeader = () => import('../../components/Organisms/Header/Header.vue' /* webpackChunkName: "components/hc-header" */).then(c => wrapFunctional(c.default || c))
export const HcHeaderSidebar = () => import('../../components/Organisms/HeaderSidebar/HeaderSidebar.vue' /* webpackChunkName: "components/hc-header-sidebar" */).then(c => wrapFunctional(c.default || c))
export const HcListCards = () => import('../../components/Organisms/ListCards/ListCards.vue' /* webpackChunkName: "components/hc-list-cards" */).then(c => wrapFunctional(c.default || c))
export const HcLoading = () => import('../../components/Organisms/Loading/Loading.vue' /* webpackChunkName: "components/hc-loading" */).then(c => wrapFunctional(c.default || c))
export const HcLoadingMessage = () => import('../../components/Organisms/LoadingMessages/LoadingMessage.vue' /* webpackChunkName: "components/hc-loading-message" */).then(c => wrapFunctional(c.default || c))
export const HcSideBar = () => import('../../components/Organisms/SideBar/SideBar.vue' /* webpackChunkName: "components/hc-side-bar" */).then(c => wrapFunctional(c.default || c))
export const HcWelcomeBanner = () => import('../../components/Organisms/WelcomeBanner/WelcomeBanner.vue' /* webpackChunkName: "components/hc-welcome-banner" */).then(c => wrapFunctional(c.default || c))
export const HcArticleFeedback = () => import('../../components/Molecules/ArticleFeedback/ArticleFeedback.vue' /* webpackChunkName: "components/hc-article-feedback" */).then(c => wrapFunctional(c.default || c))
export const HcArticleFeedbackComment = () => import('../../components/Molecules/ArticleFeedbackComment/ArticleFeedbackComment.vue' /* webpackChunkName: "components/hc-article-feedback-comment" */).then(c => wrapFunctional(c.default || c))
export const HcCard = () => import('../../components/Molecules/Card/Card.vue' /* webpackChunkName: "components/hc-card" */).then(c => wrapFunctional(c.default || c))
export const HcCardSection = () => import('../../components/Molecules/CardSection/CardSection.vue' /* webpackChunkName: "components/hc-card-section" */).then(c => wrapFunctional(c.default || c))
export const HcCardStillNeedHelp = () => import('../../components/Molecules/CardStillNeedHelp/CardStillNeedHelp.vue' /* webpackChunkName: "components/hc-card-still-need-help" */).then(c => wrapFunctional(c.default || c))
export const HcChatbotWelcomeMessage = () => import('../../components/Molecules/ChatbotWelcomeMessage/ChatbotWelcomeMessage.vue' /* webpackChunkName: "components/hc-chatbot-welcome-message" */).then(c => wrapFunctional(c.default || c))
export const HcEmptySearch = () => import('../../components/Molecules/EmptySearch/EmptySearch.vue' /* webpackChunkName: "components/hc-empty-search" */).then(c => wrapFunctional(c.default || c))
export const HcFormRequest = () => import('../../components/Molecules/FormRequest/FormRequest.vue' /* webpackChunkName: "components/hc-form-request" */).then(c => wrapFunctional(c.default || c))
export const HcFormRequestSummary = () => import('../../components/Molecules/FormRequestSummary/FormRequestSummary.vue' /* webpackChunkName: "components/hc-form-request-summary" */).then(c => wrapFunctional(c.default || c))
export const HcHeaderPrimary = () => import('../../components/Molecules/HeaderPrimary/HeaderPrimary.vue' /* webpackChunkName: "components/hc-header-primary" */).then(c => wrapFunctional(c.default || c))
export const HcLanguageSwitch = () => import('../../components/Molecules/LanguageSwitch/LanguageSwitch.vue' /* webpackChunkName: "components/hc-language-switch" */).then(c => wrapFunctional(c.default || c))
export const HcLink = () => import('../../components/Molecules/Link/Link.vue' /* webpackChunkName: "components/hc-link" */).then(c => wrapFunctional(c.default || c))
export const HcLinksArticles = () => import('../../components/Molecules/LinksArticles/LinksArticles.vue' /* webpackChunkName: "components/hc-links-articles" */).then(c => wrapFunctional(c.default || c))
export const HcResultSearch = () => import('../../components/Molecules/ResultSearch/ResultSearch.vue' /* webpackChunkName: "components/hc-result-search" */).then(c => wrapFunctional(c.default || c))
export const HcSearchBar = () => import('../../components/Molecules/SearchBar/SearchBar.vue' /* webpackChunkName: "components/hc-search-bar" */).then(c => wrapFunctional(c.default || c))
export const HcSendHelp = () => import('../../components/Molecules/SendHelp/SendHelp.vue' /* webpackChunkName: "components/hc-send-help" */).then(c => wrapFunctional(c.default || c))
export const HcSocialIcons = () => import('../../components/Molecules/SocialIcons/SocialIcons.vue' /* webpackChunkName: "components/hc-social-icons" */).then(c => wrapFunctional(c.default || c))
export const HcStepNavigation = () => import('../../components/Molecules/StepNavigation/StepNavigation.vue' /* webpackChunkName: "components/hc-step-navigation" */).then(c => wrapFunctional(c.default || c))
export const HcStepTimeline = () => import('../../components/Molecules/StepTimeline/StepTimeline.vue' /* webpackChunkName: "components/hc-step-timeline" */).then(c => wrapFunctional(c.default || c))
export const HcSummaryFeedback = () => import('../../components/Molecules/SummaryFeedback/SummaryFeedback.vue' /* webpackChunkName: "components/hc-summary-feedback" */).then(c => wrapFunctional(c.default || c))
export const HcSummaryScreeningFeedbackModal = () => import('../../components/Molecules/SummaryScreeningFeedbackModal/SummaryScreeningFeedbackModal.vue' /* webpackChunkName: "components/hc-summary-screening-feedback-modal" */).then(c => wrapFunctional(c.default || c))
export const HcTagsCategories = () => import('../../components/Molecules/TagsCategories/TagsCategories.vue' /* webpackChunkName: "components/hc-tags-categories" */).then(c => wrapFunctional(c.default || c))
export const HcTicketModal = () => import('../../components/Molecules/TicketModal/TicketModal.vue' /* webpackChunkName: "components/hc-ticket-modal" */).then(c => wrapFunctional(c.default || c))
export const HcTicketPartnersForm = () => import('../../components/Molecules/TicketPartnersForm/TicketPartnersForm.vue' /* webpackChunkName: "components/hc-ticket-partners-form" */).then(c => wrapFunctional(c.default || c))
export const HcTicketReason = () => import('../../components/Molecules/TicketReason/TicketReason.vue' /* webpackChunkName: "components/hc-ticket-reason" */).then(c => wrapFunctional(c.default || c))
export const HcTicketSubject = () => import('../../components/Molecules/TicketSubject/TicketSubject.vue' /* webpackChunkName: "components/hc-ticket-subject" */).then(c => wrapFunctional(c.default || c))
export const HcContactUs = () => import('../../components/Templates/ContactUs/ContactUs.vue' /* webpackChunkName: "components/hc-contact-us" */).then(c => wrapFunctional(c.default || c))
export const HcHome = () => import('../../components/Templates/Home/Home.vue' /* webpackChunkName: "components/hc-home" */).then(c => wrapFunctional(c.default || c))
export const HcInternalPage = () => import('../../components/Templates/InternalPage/InternalPage.vue' /* webpackChunkName: "components/hc-internal-page" */).then(c => wrapFunctional(c.default || c))
export const HcHeaderSidebarMenu = () => import('../../components/Organisms/HeaderSidebar/HeaderSidebarMenu/HeaderSidebarMenu.vue' /* webpackChunkName: "components/hc-header-sidebar-menu" */).then(c => wrapFunctional(c.default || c))
export const HcConfirmationModal = () => import('../../components/Molecules/FormRequest/Modals/ConfirmationModal.vue' /* webpackChunkName: "components/hc-confirmation-modal" */).then(c => wrapFunctional(c.default || c))
export const HcSelfieDocumentExampleModal = () => import('../../components/Molecules/FormRequest/Modals/SelfieDocumentExampleModal.vue' /* webpackChunkName: "components/hc-selfie-document-example-modal" */).then(c => wrapFunctional(c.default || c))
export const HcStep2Body = () => import('../../components/Molecules/FormRequest/Step2/Step2-body.vue' /* webpackChunkName: "components/hc-step2-body" */).then(c => wrapFunctional(c.default || c))
export const HcStep2RequesterEmail = () => import('../../components/Molecules/FormRequest/Step2/Step2-requesterEmail.vue' /* webpackChunkName: "components/hc-step2-requester-email" */).then(c => wrapFunctional(c.default || c))
export const HcStep2RequesterName = () => import('../../components/Molecules/FormRequest/Step2/Step2-requesterName.vue' /* webpackChunkName: "components/hc-step2-requester-name" */).then(c => wrapFunctional(c.default || c))
export const HcStep3Address = () => import('../../components/Molecules/FormRequest/Step3/Step3-address.vue' /* webpackChunkName: "components/hc-step3-address" */).then(c => wrapFunctional(c.default || c))
export const HcStep3AddressComplement = () => import('../../components/Molecules/FormRequest/Step3/Step3-addressComplement.vue' /* webpackChunkName: "components/hc-step3-address-complement" */).then(c => wrapFunctional(c.default || c))
export const HcStep3AffiliateName = () => import('../../components/Molecules/FormRequest/Step3/Step3-affiliateName.vue' /* webpackChunkName: "components/hc-step3-affiliate-name" */).then(c => wrapFunctional(c.default || c))
export const HcStep3BackDocumentFiles = () => import('../../components/Molecules/FormRequest/Step3/Step3-backDocumentFiles.vue' /* webpackChunkName: "components/hc-step3-back-document-files" */).then(c => wrapFunctional(c.default || c))
export const HcStep3Cnpj = () => import('../../components/Molecules/FormRequest/Step3/Step3-cnpj.vue' /* webpackChunkName: "components/hc-step3-cnpj" */).then(c => wrapFunctional(c.default || c))
export const HcStep3Cpf = () => import('../../components/Molecules/FormRequest/Step3/Step3-cpf.vue' /* webpackChunkName: "components/hc-step3-cpf" */).then(c => wrapFunctional(c.default || c))
export const HcStep3CurrentDocumentFiles = () => import('../../components/Molecules/FormRequest/Step3/Step3-currentDocumentFiles.vue' /* webpackChunkName: "components/hc-step3-current-document-files" */).then(c => wrapFunctional(c.default || c))
export const HcStep3CurrentPartnersForm = () => import('../../components/Molecules/FormRequest/Step3/Step3-currentPartnersForm.vue' /* webpackChunkName: "components/hc-step3-current-partners-form" */).then(c => wrapFunctional(c.default || c))
export const HcStep3FrontDocumentFiles = () => import('../../components/Molecules/FormRequest/Step3/Step3-frontDocumentFiles.vue' /* webpackChunkName: "components/hc-step3-front-document-files" */).then(c => wrapFunctional(c.default || c))
export const HcStep3HasComplement = () => import('../../components/Molecules/FormRequest/Step3/Step3-hasComplement.vue' /* webpackChunkName: "components/hc-step3-has-complement" */).then(c => wrapFunctional(c.default || c))
export const HcStep3NewCnpj = () => import('../../components/Molecules/FormRequest/Step3/Step3-newCnpj.vue' /* webpackChunkName: "components/hc-step3-new-cnpj" */).then(c => wrapFunctional(c.default || c))
export const HcStep3NewCpf = () => import('../../components/Molecules/FormRequest/Step3/Step3-newCpf.vue' /* webpackChunkName: "components/hc-step3-new-cpf" */).then(c => wrapFunctional(c.default || c))
export const HcStep3NewDocumentFiles = () => import('../../components/Molecules/FormRequest/Step3/Step3-newDocumentFiles.vue' /* webpackChunkName: "components/hc-step3-new-document-files" */).then(c => wrapFunctional(c.default || c))
export const HcStep3Number = () => import('../../components/Molecules/FormRequest/Step3/Step3-number.vue' /* webpackChunkName: "components/hc-step3-number" */).then(c => wrapFunctional(c.default || c))
export const HcStep3ProducerName = () => import('../../components/Molecules/FormRequest/Step3/Step3-producerName.vue' /* webpackChunkName: "components/hc-step3-producer-name" */).then(c => wrapFunctional(c.default || c))
export const HcStep3ProofResidenceFiles = () => import('../../components/Molecules/FormRequest/Step3/Step3-proofResidenceFiles.vue' /* webpackChunkName: "components/hc-step3-proof-residence-files" */).then(c => wrapFunctional(c.default || c))
export const HcStep3SelfieDocumentFiles = () => import('../../components/Molecules/FormRequest/Step3/Step3-selfieDocumentFiles.vue' /* webpackChunkName: "components/hc-step3-selfie-document-files" */).then(c => wrapFunctional(c.default || c))
export const HcStep3Title = () => import('../../components/Molecules/FormRequest/Step3/Step3-title.vue' /* webpackChunkName: "components/hc-step3-title" */).then(c => wrapFunctional(c.default || c))
export const HcStep3UserNewEmail = () => import('../../components/Molecules/FormRequest/Step3/Step3-userNewEmail.vue' /* webpackChunkName: "components/hc-step3-user-new-email" */).then(c => wrapFunctional(c.default || c))
export const HcStep4BackDocumentFiles = () => import('../../components/Molecules/FormRequest/Step4/Step4-backDocumentFiles.vue' /* webpackChunkName: "components/hc-step4-back-document-files" */).then(c => wrapFunctional(c.default || c))
export const HcStep4FrontDocumentFiles = () => import('../../components/Molecules/FormRequest/Step4/Step4-frontDocumentFiles.vue' /* webpackChunkName: "components/hc-step4-front-document-files" */).then(c => wrapFunctional(c.default || c))
export const HcStep4NewCnpj = () => import('../../components/Molecules/FormRequest/Step4/Step4-newCnpj.vue' /* webpackChunkName: "components/hc-step4-new-cnpj" */).then(c => wrapFunctional(c.default || c))
export const HcStep4NewCpf = () => import('../../components/Molecules/FormRequest/Step4/Step4-newCpf.vue' /* webpackChunkName: "components/hc-step4-new-cpf" */).then(c => wrapFunctional(c.default || c))
export const HcStep4NewDocumentFiles = () => import('../../components/Molecules/FormRequest/Step4/Step4-newDocumentFiles.vue' /* webpackChunkName: "components/hc-step4-new-document-files" */).then(c => wrapFunctional(c.default || c))
export const HcStep4NewPartnersForm = () => import('../../components/Molecules/FormRequest/Step4/Step4-newPartnersForm.vue' /* webpackChunkName: "components/hc-step4-new-partners-form" */).then(c => wrapFunctional(c.default || c))
export const HcStep4ProofResidenceFiles = () => import('../../components/Molecules/FormRequest/Step4/Step4-proofResidenceFiles.vue' /* webpackChunkName: "components/hc-step4-proof-residence-files" */).then(c => wrapFunctional(c.default || c))
export const HcStep4SelfieDocumentFiles = () => import('../../components/Molecules/FormRequest/Step4/Step4-selfieDocumentFiles.vue' /* webpackChunkName: "components/hc-step4-selfie-document-files" */).then(c => wrapFunctional(c.default || c))
export const HcStep4Title = () => import('../../components/Molecules/FormRequest/Step4/Step4-title.vue' /* webpackChunkName: "components/hc-step4-title" */).then(c => wrapFunctional(c.default || c))
export const HcStep4UserNewEmail = () => import('../../components/Molecules/FormRequest/Step4/Step4-userNewEmail.vue' /* webpackChunkName: "components/hc-step4-user-new-email" */).then(c => wrapFunctional(c.default || c))
export const HcStep4 = () => import('../../components/Molecules/FormRequest/Step4/Step4.vue' /* webpackChunkName: "components/hc-step4" */).then(c => wrapFunctional(c.default || c))
export const HcTicketModalBody = () => import('../../components/Molecules/TicketModal/TicketModalBody/TicketModalBody.vue' /* webpackChunkName: "components/hc-ticket-modal-body" */).then(c => wrapFunctional(c.default || c))
export const HcTicketModalFooter = () => import('../../components/Molecules/TicketModal/TicketModalFooter/TicketModalFooter.vue' /* webpackChunkName: "components/hc-ticket-modal-footer" */).then(c => wrapFunctional(c.default || c))
export const HcTicketPartnersFormAlert = () => import('../../components/Molecules/TicketPartnersForm/alert/TicketPartnersFormAlert.vue' /* webpackChunkName: "components/hc-ticket-partners-form-alert" */).then(c => wrapFunctional(c.default || c))
export const HcTicketPartnersFormButton = () => import('../../components/Molecules/TicketPartnersForm/button/TicketPartnersFormButton.vue' /* webpackChunkName: "components/hc-ticket-partners-form-button" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCard = () => import('../../components/Molecules/TicketPartnersForm/card/PartnerCard.vue' /* webpackChunkName: "components/hc-partner-card" */).then(c => wrapFunctional(c.default || c))
export const HcTicketPartnersFormLimitCheckbox = () => import('../../components/Molecules/TicketPartnersForm/checkbox/TicketPartnersFormLimitCheckbox.vue' /* webpackChunkName: "components/hc-ticket-partners-form-limit-checkbox" */).then(c => wrapFunctional(c.default || c))
export const HcHeaderSidebarMenuLink = () => import('../../components/Organisms/HeaderSidebar/HeaderSidebarMenu/HeaderSidebarMenuLink/HeaderSidebarMenuLink.vue' /* webpackChunkName: "components/hc-header-sidebar-menu-link" */).then(c => wrapFunctional(c.default || c))
export const HcHeaderSidebarMenuMyRequests = () => import('../../components/Organisms/HeaderSidebar/HeaderSidebarMenu/HeaderSidebarMenuMyRequests/HeaderSidebarMenuMyRequests.vue' /* webpackChunkName: "components/hc-header-sidebar-menu-my-requests" */).then(c => wrapFunctional(c.default || c))
export const HcHowDoYouWantToContinue = () => import('../../components/Molecules/SummaryScreeningFeedbackModal/variants/HowDoYouWantToContinue/HowDoYouWantToContinue.vue' /* webpackChunkName: "components/hc-how-do-you-want-to-continue" */).then(c => wrapFunctional(c.default || c))
export const HcThankYouForYourFeedback = () => import('../../components/Molecules/SummaryScreeningFeedbackModal/variants/ThankYouForYourFeedback/ThankYouForYourFeedback.vue' /* webpackChunkName: "components/hc-thank-you-for-your-feedback" */).then(c => wrapFunctional(c.default || c))
export const HcWasThisInformationHelpful = () => import('../../components/Molecules/SummaryScreeningFeedbackModal/variants/WasThisInformationHelpful/WasThisInformationHelpful.vue' /* webpackChunkName: "components/hc-was-this-information-helpful" */).then(c => wrapFunctional(c.default || c))
export const HcTicketModalBodyHelpMessage = () => import('../../components/Molecules/TicketModal/TicketModalBody/TicketModalBodyHelpMessage/TicketModalBodyHelpMessage.vue' /* webpackChunkName: "components/hc-ticket-modal-body-help-message" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCardBody = () => import('../../components/Molecules/TicketPartnersForm/card/body/PartnerCardBody.vue' /* webpackChunkName: "components/hc-partner-card-body" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCardFooter = () => import('../../components/Molecules/TicketPartnersForm/card/footer/PartnerCardFooter.vue' /* webpackChunkName: "components/hc-partner-card-footer" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCardHeader = () => import('../../components/Molecules/TicketPartnersForm/card/header/PartnerCardHeader.vue' /* webpackChunkName: "components/hc-partner-card-header" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCardDeleteModal = () => import('../../components/Molecules/TicketPartnersForm/modals/deleteModal/PartnerCardDeleteModal.vue' /* webpackChunkName: "components/hc-partner-card-delete-modal" */).then(c => wrapFunctional(c.default || c))
export const HcHeaderSidebarMenuLinkManageMyBusiness = () => import('../../components/Organisms/HeaderSidebar/HeaderSidebarMenu/HeaderSidebarMenuLink/HeaderSidebarMenuLinkManageMyBusiness/HeaderSidebarMenuLinkManageMyBusiness.vue' /* webpackChunkName: "components/hc-header-sidebar-menu-link-manage-my-business" */).then(c => wrapFunctional(c.default || c))
export const HcHeaderSidebarMenuLinkMyPurchases = () => import('../../components/Organisms/HeaderSidebar/HeaderSidebarMenu/HeaderSidebarMenuLink/HeaderSidebarMenuLinkMyPurchases/HeaderSidebarMenuLinkMyPurchases.vue' /* webpackChunkName: "components/hc-header-sidebar-menu-link-my-purchases" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCardBodyInputSelfieSupportingText = () => import('../../components/Molecules/TicketPartnersForm/card/body/inputs/PartnerCardBodyInputSelfieSupportingText.vue' /* webpackChunkName: "components/hc-partner-card-body-input-selfie-supporting-text" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCardBodyInputs = () => import('../../components/Molecules/TicketPartnersForm/card/body/inputs/PartnerCardBodyInputs.vue' /* webpackChunkName: "components/hc-partner-card-body-inputs" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCardBodyWrapper = () => import('../../components/Molecules/TicketPartnersForm/card/body/wrapper/PartnerCardBodyWrapper.vue' /* webpackChunkName: "components/hc-partner-card-body-wrapper" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCardConfirmedFooter = () => import('../../components/Molecules/TicketPartnersForm/card/footer/variants/comfirmed/PartnerCardConfirmedFooter.vue' /* webpackChunkName: "components/hc-partner-card-confirmed-footer" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCardEditingFooter = () => import('../../components/Molecules/TicketPartnersForm/card/footer/variants/editing/PartnerCardEditingFooter.vue' /* webpackChunkName: "components/hc-partner-card-editing-footer" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCardNewFooter = () => import('../../components/Molecules/TicketPartnersForm/card/footer/variants/new/PartnerCardNewFooter.vue' /* webpackChunkName: "components/hc-partner-card-new-footer" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCardConfirmedHeader = () => import('../../components/Molecules/TicketPartnersForm/card/header/variants/confirmed/PartnerCardConfirmedHeader.vue' /* webpackChunkName: "components/hc-partner-card-confirmed-header" */).then(c => wrapFunctional(c.default || c))
export const HcPartnerCardNewHeader = () => import('../../components/Molecules/TicketPartnersForm/card/header/variants/new/PartnerCardNewHeader.vue' /* webpackChunkName: "components/hc-partner-card-new-header" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
